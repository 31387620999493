'use client';
import { motion } from 'framer-motion';
import { PrismicNextImage } from '@prismicio/next';
import { Box, Container } from '@mui/material';

/**
 * @typedef {import("@prismicio/client").Content.LogoSliderSlice} LogoSliderSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<LogoSliderSlice>} LogoSliderProps
 * @param {LogoSliderProps}
 */

const styles = {
  section: {
    padding: { xs: '2rem 0', sm: '3rem 0' },
    backgroundColor: '#fafafa',
  },
  imgContainer: {
    display: 'flex',
    overflow: 'hidden',
    maskImage: 'linear-gradient(to right,transparent,black,transparent)',
  },
};

const LogoSlider = ({ slice }) => {
  const { items } = slice;
  const itemsForAnimation = [...items, ...items];

  return (
    <Box
      component={'section'}
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      sx={styles.section}>
      <Container>
        <Box sx={styles.imgContainer}>
          <motion.div
            style={{
              display: 'flex',
              gap: '3.5rem',
              paddingRight: '3.5rem',
              flex: 'none',
            }}
            animate={{
              translateX: '-50%',
            }}
            transition={{
              repeatType: 'loop',
              repeat: Infinity,
              duration: 40,
              ease: 'linear',
            }}>
            {itemsForAnimation.map(({ logo }, index) => (
              <PrismicNextImage
                key={index}
                field={logo}
                style={{ height: '32px', width: 'auto' }}
              />
            ))}
          </motion.div>
        </Box>
      </Container>
    </Box>
  );
};

export default LogoSlider;
